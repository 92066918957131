<template lang="pug">
b-form(@submit.stop.prevent="onSubmit")
  .subtitle
    h4 Bienvenido a Sill Laboratorio
    p Registro de usuario Retail

  .form-group
    .icon-container
      b-icon(icon="envelope")
    b-form-input.form-control(
      v-model="email"
      type="email"
      required
      placeholder="Correo Retail"
    )
    b-form-text Ingrese un correo registrado como Retail

  .w-100.d-inline-flex.flex-column.align-items-center
    b-button.boton(type="submit")
      | Enviar
      b-spinner(v-if="loading" small)
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      email: '',
      loading: false,
    }
  },
  methods: {
    ...mapActions('AuthenticationStore', ['registerRetailUser']),
    onSubmit() {
      if (this.loading) return
      this.loading = true
      this.registerRetailUser({ email: this.email })
        .then((res) => {
          this.email = ''
          this.$bvToast.toast(res.data?.message, {
            title: 'Correo enviado',
            variant: 'success',
            solid: true,
          })
        })

        .catch((err) => {
          const {message} = err.response.data
          this.$bvToast.toast(message, {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
